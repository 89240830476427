<template>
  <a-row type="flex" align="middle">
    <a-col :span="19">
        <TypeDemandesList />
    </a-col>
  </a-row>
</template>
<script>
import TypeDemandesList from "./TypeDemandesList";
export default {
  name: "TypeDemandes",
  components: { TypeDemandesList },
};
</script>
