<template>
  <a-modal
    :title="title"
    :visible="visible"
    @cancel="handleCancel"
    okText="Valider"
    @ok="handleOk"
    :okButtonProps="{ props: { htmlType: 'submit' } }"
  >
    <a-form
      :layout="formLayout"
      id="add_type_demande"
      name="add_type_demande"
      class="adherent_form"
      :form="form"
    >
      <a-row>
        <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Code">
            <a-input
              v-decorator="[
                'code',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Code est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Nom [fr]">
            <a-input
              v-decorator="[
                'nom_fr',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Nom [fr] est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
        <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Nom [ar]">
            <a-input
              v-decorator="[
                'nom_ar',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Nom [ar] est obligatoire!',
                    },
                  ],
                },
              ]"
            />
          </a-form-item>
        </a-col>
               <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Rapport">
        
              <a-select      v-decorator="[
                'rapport_id',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Nom [ar] est obligatoire!',
                    },
                  ],
                },
              ]" 
                                    style="width: 200px">
                  <a-select-option :key="rapport.id" v-for="rapport in rapports">
                       {{ rapport.nom_affichage }}
                  </a-select-option>
              </a-select>
          </a-form-item>
        </a-col>
               <a-col :span="18">
          <a-form-item v-bind="formItemLayout" label="Paiement">
        
              <a-select  v-decorator="[
                'exercice_type_reglement_id',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Type paiemeny est obligatoire!',
                    },
                  ],
                },
              ]" 
                                    style="width: 200px">
                  <a-select-option :key="typePaiement.exercice_type_reglement_id" v-for="typePaiement in typePaiements">
                       {{ typePaiement.nom_fr }} ({{ typePaiement.code}})
                  </a-select-option>
              </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <template slot="footer">
      <a-button
        key="submit"
        type="primary"
        :loading="processing"
        @click="handleOk"
        >Valider</a-button
      >
    </template>
  </a-modal>
</template>
<script>
export default {
  name: "TypeDemandeEditor",
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  mounted() {
      this.$nextTick(() => {
        this.form.setFieldsValue(this.get(this, "typeDemande"));
        this.form.setFieldsValue({rapport_id : this.get(this, "typeDemande.rapport.id")});
      });
  },
  data() {
    return {
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 },
        },
      },
    };
  },
  props: ["visible", "typeDemande", "processing", 'rapports','typePaiements'],
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleCancel() {
      this.$emit("close");
    },
    handleOk() {
      this.$emit("submit");
    },
  },
};
</script>
