var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TypeDemandeEditor", {
        key: _vm.newTypeDemandeComponentKey,
        ref: "typeDemandeForm",
        attrs: {
          "type-demande": _vm.selectedTypeDemande,
          "type-paiements": _vm.type_paiements,
          visible: _vm.typeDemandeModalVisible,
          processing: _vm.typeDemandeProcessing,
          rapports: _vm.rapports
        },
        on: {
          close: _vm.handleTypeDemandeFormClosing,
          submit: _vm.handleTypeDemandeFormSubmit
        }
      }),
      _c(
        "h1",
        { staticStyle: { "margin-left": "0%", "margin-bottom": "4%" } },
        [_vm._v("Type des demandes")]
      ),
      _c(
        "a-row",
        {
          staticClass: "mrgin_btm",
          attrs: { type: "flex", justify: "space-between" }
        },
        [
          _c(
            "a-col",
            { attrs: { span: 8 } },
            [
              _c(
                "a-button",
                {
                  staticClass: "editable-add-btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.addTypeDemande }
                },
                [_vm._v("Ajouter type demande")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.processing } },
        [
          _vm.type_demandes.length
            ? _c("a-table", {
                attrs: {
                  bordered: "",
                  dataSource: _vm.type_demandes,
                  columns: _vm.columns,
                  rowKey: function(record) {
                    return record.id
                  },
                  pagination: false
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "row_index",
                      fn: function(text, record, index) {
                        return [_c("p", [_vm._v(_vm._s(index + 1))])]
                      }
                    },
                    {
                      key: "operation",
                      fn: function(text, record) {
                        return [
                          _vm.type_demandes.length
                            ? _c(
                                "a-popconfirm",
                                {
                                  attrs: {
                                    title:
                                      "Etes-vous sûr de vouloir supprimer cette ligne ?"
                                  },
                                  on: {
                                    confirm: function() {
                                      return _vm.onDelete(record)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "a-tooltip",
                                    { attrs: { placement: "top" } },
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c("span", [_vm._v("Suppression")])
                                      ]),
                                      _c("a-icon", {
                                        staticClass: "action_icons",
                                        attrs: {
                                          type: "delete",
                                          theme: "twoTone",
                                          twoToneColor: "#eb2f96"
                                        }
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "a-tooltip",
                            { attrs: { placement: "top" } },
                            [
                              _c("template", { slot: "title" }, [
                                _c("span", [_vm._v("Edition")])
                              ]),
                              _c("a-icon", {
                                staticClass: "action_icons",
                                staticStyle: { "margin-right": "10px" },
                                attrs: {
                                  type: "edit",
                                  theme: "twoTone",
                                  twoToneColor: "#52c41a"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleEdit(record)
                                  }
                                }
                              })
                            ],
                            2
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1354445527
                )
              })
            : _c("EmptyResult")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }