<template>
  <div>
     <TypeDemandeEditor
      :type-demande="selectedTypeDemande"
      :type-paiements="type_paiements"
      :visible="typeDemandeModalVisible"
      :processing="typeDemandeProcessing"
      :rapports="rapports"
      @close="handleTypeDemandeFormClosing"
      @submit="handleTypeDemandeFormSubmit"
      :key="newTypeDemandeComponentKey"
      ref="typeDemandeForm"
    />
    <h1 style="margin-left:0%;margin-bottom:4%">Type des demandes</h1>
    <a-row type="flex" justify="space-between" class="mrgin_btm">
      <a-col :span="8">
        <a-button
          class="editable-add-btn"
          type="primary"
          @click="addTypeDemande"
          >Ajouter type demande</a-button
        >
      </a-col>
 
    </a-row>

    <a-spin :spinning="processing">
      <a-table
        bordered
        v-if="type_demandes.length"
        :dataSource="type_demandes"
        :columns="columns"
        :rowKey="
          (record) => {
            return record.id;
          }
        "
        :pagination="false"
      >
        <template slot="row_index" slot-scope="text, record, index">
          <p>{{ index + 1 }}</p>
        </template>

        <template
          class="table-operation"
          slot="operation"
          slot-scope="text, record"
        >
          <a-popconfirm
            v-if="type_demandes.length"
            title="Etes-vous sûr de vouloir supprimer cette ligne ?"
            @confirm="() => onDelete(record)"
          >
            <a-tooltip placement="top">
              <template slot="title">
                <span>Suppression</span>
              </template>
              <a-icon
                type="delete"
                class="action_icons"
                theme="twoTone"
                twoToneColor="#eb2f96"
              />
            </a-tooltip>
          </a-popconfirm>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Edition</span>
            </template>
            <a-icon
              type="edit"
              v-on:click="handleEdit(record)"
              class="action_icons"
              style="margin-right:10px;"
              theme="twoTone"
              twoToneColor="#52c41a"
            />
          </a-tooltip>
        </template>
      </a-table>
      <EmptyResult v-else />
    </a-spin>
  </div>
</template>
<script>
  // import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import { mapState, mapActions } from "vuex";
import EmptyResult from "@/components/common/EmptyResult";
import TypeDemandeEditor from "./TypeDemandeEditor";
 
const columns = [
  {
    title: "N°",
    scopedSlots: { customRender: "row_index" },
  },
  {
    title: "Nom fr",
    dataIndex: "nom_fr",
  },
  {
    title: "Nom ar",
    dataIndex: "nom_ar",
  },
  {
    title: "Code",
    dataIndex: "code",
  },
  {
    title: "Operation",
    scopedSlots: { customRender: "operation" },
  },
];
export default {
  name: "TypeDemandesList",
  components: {
    EmptyResult,
    TypeDemandeEditor
  },
  created() {
    this.fetchData();
  },
  data() {
    return {
      processing: true,
      typeDemandeProcessing: false,
      columns,
      selectedTypeDemande: null,
      typeDemandeModalVisible: false,
      typePaimentDemande: false,
      newTypeDemandeComponentKey: 0,
    };
  },
  computed: {
    ...mapState({
      type_demandes: (state) => state.type_demandes.type_demandes,
      type_paiements: (state) => state.type_paiements.type_paiements,
      rapports: (state) => state.rapports.rapports
    }),
  },
  methods: {
    fetchData() {
      this.startProcessing();
      this.$store.cache.dispatch("fetchRapports");
      this.$store.cache.dispatch("fetchTypePaiements");
      this.getData()
        .finally(() => this.stopProcessing());
    },

    generateNewTypeComponenetKey() {
      this.newTypeDemandeComponentKey = this.getRandomInt();
    },
    handleTypeDemandeFormSubmit() {
      const form = this.$refs["typeDemandeForm"].form;
      let that = this;
      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.startTypePaiementFormProcessing();
          if (this.selectedTypeDemande) {
            this.update({ id: this.selectedTypeDemande.id, ...values })
              .then((response) => {
                that.stopTypeDemandeFormProcessing();
                form.resetFields();
                that.clearselectedTypeDemande();
                that.closeTypeDemandeModal();
                that.fetchData();
              })
              .catch((err) => {
                that.stopTypeDemandeFormProcessing();
              });
            return;
          }
          this.add({
            ...values,
          })
            .then((response) => {
              that.stopTypeDemandeFormProcessing();
              form.resetFields();
              that.clearselectedTypeDemande();
              that.closeTypeDemandeModal();
              that.fetchData();
            })
            .catch((err) => {
              that.stopTypeDemandeFormProcessing();
            });
        } else {
          that.stopTypeDemandeFormProcessing();
        }
      });
    },
    handleTypeDemandeFormClosing() {
      this.closeTypeDemandeModal();
    },
    clearselectedTypeDemande() {
      this.selectedTypeDemande = null;
    },
    closeTypeDemandeModal() {
      this.typeDemandeModalVisible = false;
    },
    openTypeDemandeModal() {
      this.typeDemandeModalVisible = true;
    },
    addTypeDemande() {
      this.generateNewTypeComponenetKey();
      this.openTypeDemandeModal();
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    startTypePaiementFormProcessing() {
      this.typeDemandeProcessing = true;
    },
    stopTypeDemandeFormProcessing() {
      this.typeDemandeProcessing = false;
    },
    onDelete(id) {
      this.remove(id)
        .then(() => this.fetchData())
        .catch(() => this._throwAnError());
    },
    handleEdit(typeDemande) {
      this.selectedTypeDemande = typeDemande;
      this.generateNewTypeComponenetKey();
      this.openTypeDemandeModal();
    },
    ...mapActions({
      getData: "fetchTypeDemandes",
      add: "storeTypeDemande",
      update: "updateTypeDemandes",
      remove: "destroyTypeDemande",
    }),
  },
};
</script>
<style scoped>
td {
  width: max-content;
}

.decision {
  text-decoration: underline;
  margin-left: 3%;
}

.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}
.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 20px !important;
}

.decission_btn {
  text-decoration: underline;
}

.action_icons {
  margin: 4px;
  font-size: large;
}
.mrgin_btm {
  margin-bottom: 2%;
}
.txt-end {
  text-align: end;
}
</style>
